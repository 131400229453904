import * as React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import "../components/layout.css"

import DeadPixelLogo from "../images/dead-pixel-logo-link-cubed.jpg"

const Deadpixel = () => (
  <div className="dp_layout">
    <Seo title="deadpixel.fix" />
    <div className="dp_cont">

      <div className="dp_logo"> 
        <img src={DeadPixelLogo} alt="dead-pixel-logo" width="100%" />
      </div>
      
      <h1>deadpixel.fix</h1>

      <div className="dp_card_cont">
        <div className="dp_card">
          <a href="https://youtu.be/90eUMTd27Ec" target="_blank" rel="noopener noreferrer">
            <h3>Last Video</h3>
          </a>
        </div>

        <div className="dp_card">
          <a href="https://www.youtube.com/channel/UCG0Wfgg9ZlVFQe4vA7PVHeQ" target="_blank" rel="noopener noreferrer">
            <h3>YouTube Channel</h3>
          </a>
        </div>

        <div className="dp_card">
          <a href="https://www.honestguide.eu/" target="_blank" rel="noopener noreferrer">
            <h3>Web Site</h3>
          </a>
        </div>


        <div className="dp_card">
          <a href="https://www.instagram.com/deadpixel.fix/" target="_blank" rel="noopener noreferrer">
            <h3>Instagram</h3>
          </a>
        </div>

        <div className="dp_card">
          <a href="https://www.facebook.com/Deadpixelfix-104974648223471" target="_blank" rel="noopener noreferrer">
            <h3>Facebook</h3>
          </a>
        </div>
      </div>
    </div>
    
    <footer className="hg_footer">
      <p>powered by</p>
      <Link to="/">LINKCUBED</Link>
    </footer>
  </div>
)

export default Deadpixel
